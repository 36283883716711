import { motion } from 'framer-motion';
import { textVariant } from '../utils/motion';
import { SectionWrapper } from '../hoc';

const HeroContact = () => {
  
  return (
    <section className='relative w-full md:min-h-[100px] ss:min-h-[100px] 
    items-center flex'>
        <div className='relative items-center w-full max-w-[72rem] heroProducts
        md:mt-28 ss:mt-12 mt-12 md:rounded-[18px] ss:rounded-[18px] 
        rounded-[12px] flex md:p-10 ss:p-10 p-6'
        >
            <div className='w-full flex flex-col md:gap-0 gap-1'>
                <motion.div variants={textVariant()}
                className='flex items-center'
                >
                    <h1 className='text-secondary font-bold md:text-[35px]
                    ss:text-[40px] text-[30px]'>
                        Contact Us
                    </h1>
                </motion.div> 

                <motion.div variants={textVariant()}
                className='flex md:gap-4 ss:gap-2 gap-2 
                text-white md:text-[15px] ss:text-[15px] text-[13px]'
                >
                    <a href='/' className='hover:text-secondary'>
                        Home
                    </a>
                    <p> {'>'} </p>
                    <p>Contact Us</p>
                </motion.div>           
            </div>
        </div>
    </section>  
  )
};

export default SectionWrapper(HeroContact, '');